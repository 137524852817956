// This is the scss entry file
//import "../styles/pbi/index.scss";

import "../styles/main.css";
// import "../styles/pbi/index.scss";


// This is the scss entry file
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import Axios from "axios";

//import Sortable from "sortablejs";
// import Swiper from 'swiper';
// import { Navigation, Pagination } from 'swiper/modules';
// import 'swiper/css';

import "@glidejs/glide/dist/css/glide.core.min.css";


import intersect from '@alpinejs/intersect'
import Clipboard from "@ryangjchandler/alpine-clipboard"

Alpine.plugin(intersect)
Alpine.plugin(persist);
Alpine.plugin(Clipboard)
window.axios = Axios;

window.Alpine = Alpine;

// Navigation, Menu Button
const navMenu = document.getElementById('navigation-menu');
const btnMenu = document.getElementById('btn-menu');
if (navMenu) {
    btnMenu.addEventListener('click', () => {
        navMenu.classList.toggle('active');
        btnMenu.classList.toggle('active');
    });
    const hideDropdownMenusWithoutItems = () => {
        const openDropdownMenus = document.querySelectorAll('.open-dropdown-menu');
        openDropdownMenus.forEach(openDropdownMenu => {
            const parentMenuItem = openDropdownMenu.closest('li');
            if (parentMenuItem) {
                const dropdownMenu = parentMenuItem.querySelector('.dropdown-menu');
                if (!dropdownMenu) {
                    openDropdownMenu.style.display = 'none';
                }
            }
        });
    };
    const toggleDropdownMenus = () => {
        const openDropdownMenus = document.querySelectorAll('.open-dropdown-menu');
        openDropdownMenus.forEach(openDropdownMenu => {
            openDropdownMenu.addEventListener('click', event => {
                event.preventDefault();
                const parentItem = openDropdownMenu.closest('li');
                if (parentItem) {
                    const dropdownMenu = parentItem.querySelector('.dropdown-menu');
                    if (dropdownMenu) {
                        const isOpen = dropdownMenu.classList.toggle('active');
                        openDropdownMenu.classList.toggle('active', isOpen);
                        openDropdownMenu.innerText = isOpen ? 'remove' : 'add';
                    }
                }
            });
        });
    };
    hideDropdownMenusWithoutItems();
    toggleDropdownMenus();
}

//window.Sortable = Sortable;
Alpine.store("gridlist", {
    listView:Alpine.$persist(true).as("st_listView")
})

Alpine.store("cart", {
    items: Alpine.$persist([]).as("st_cart"),
    cartData: [],
    showCart:false,
    rmByVal(id) {
        const i = this.items.indexOf(id);
        this.items.splice(i, 1);
        const index = this.cartData.findIndex(item => item.id === id);
        if (index !== -1) {
            this.cartData.splice(index, 1);
        }
    },
    emptyCart() {
        this.items = [];
        this.cartData = []
    },
    addToCart(i) {
        if (typeof i === "string") {
            i = parseInt(i);
        }
        this.items.push(i);
        //this.getData()
    },
    inCart(i) {
        if (this.items.includes(i)) {
            return true;
        }
        return false
    },
    toggle(){
        this.showCart === false?this.getData():this.showCart = !this.showCart
    },
    getData() {
        this.cartData = []
        if (this.items.length <= 0) {
            this.showCart = !this.showCart
            return
        }
        const c = JSON.parse(JSON.stringify(this.items))
        axios.get(`/resource-hub/api/v1/products/cart/${c.join('-')}`)
            .then((response) => {
                let i = 1
                response.data.forEach(obj => {
                    obj.order = i++
                    this.cartData.push(obj)
                })
                this.showCart = !this.showCart
            })
            .catch((error) => {
                alert(error);
            })
    }
});

Alpine.start();

//Sticky sidebar toggle
// const openSidebarMenuButton = document.getElementById('open-sidebar-menu-button'),
// sidebarMenuButton = document.querySelector('.sidebar-menu-button'),
// sidebarMenuContent = document.querySelector('.sidebar-menu-content'),
// closeSidebarMenuButton = document.getElementById('close-sidebar-menu');
// if (sidebarMenuContent) {
//     openSidebarMenuButton.addEventListener('click', () => {
//         console.log('Clicked!');
//         sidebarMenuButton.classList.add('inactive');
//         sidebarMenuContent.classList.toggle('active');
//     });
//     closeSidebarMenuButton.addEventListener('click', () => {
//         sidebarMenuButton.classList.remove('inactive');
//         sidebarMenuContent.classList.remove('active');
//     });
// }

// Half Slider
// const halfSliders = document.querySelectorAll('.half-slider');
// if (halfSliders.length > 0) {
//     Swiper.use([Navigation, Pagination]);
//     halfSliders.forEach((slider) => {
//         const swiper = new Swiper(slider, {
//             navigation: {
//                 nextEl: '.half-slider-next',
//                 prevEl: '.half-slider-prev',
//             },
//             pagination: {
//                 el: '.half-slider-pagination',
//                 type: 'custom',
//                 renderCustom: function (swiper, current, total) {
//                     return current + ' / ' + total;
//                 },
//             },
//         });
//     });
// }

// Tabs Slider


window.canshare = navigator.share ? true : false;
window.shareComponent = (title,text,url) => {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: url
        })
        .catch((error) => console.log('Error sharing', error));
    } else {
        console.log('Web Share API not supported.');
    }
}




/*********
 * Glide test
 */
import Glide, { Autoplay, Swipe, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

// Home portfolio slider
if (document.getElementById('home-wine-spotlight-slider')) {
    const homeWineSpotlightSlider = new Glide('#home-wine-spotlight-slider', {
        type: 'slider',
        perView: 3,
        animationDuration: 600,
        animationTimingFunc: 'cubic-bezier( .42, 0, .58, 1 )',
        rewind: false,
        gap: 20,
        dragThreshold: 60,
        bound: true,
        // peek: 0,
        // startAt: 0,
        breakpoints: {
            1023: {
                peek: { 
                    before: 24, 
                    after: 24 
                },
                perView: 3,
                //focusAt:'center',

            },
            767: {
                perView: 2,
                peek: {
                    before: 24, 
                    after: 120
                },
            },
            639: {
                perView: 1,
                peek: {
                    before: 24, 
                    after: 120
                },
                //focusAt:'center',
                //type: 'slider',
            },
            420: {
                perView: 1,
                peek: {
                    before: 24, 
                    after: 80
                },
                //focusAt:'center',
            }
        }
    });
    homeWineSpotlightSlider.mount({Swipe, Controls, Breakpoints});
}

function blogslider() {
    if (document.getElementById('blogslider')) {
        const blogSlider = new Glide('#blogslider', {
            type: 'slider',
            perView: 4,
            animationDuration: 600,
            animationTimingFunc: 'cubic-bezier( .42, 0, .58, 1 )',
            rewind: false,
            gap: 20,
            dragThreshold: 60,
            bound: true,
            // peek: 0,
            // startAt: 0,
            breakpoints: {
                1023: {
                    peek: {
                        before: 24,
                        after: 120
                    },
                    perView: 3,
                    //focusAt:'center',

                },
                767: {
                    perView: 2,
                    peek: {
                        before: 24,
                        after: 120
                    },
                },
                639: {
                    perView: 1,
                    peek: {
                        before: 24,
                        after: 120
                    },
                    //focusAt:'center',
                    //type: 'slider',
                },
                420: {
                    perView: 1,
                    peek: {
                        before: 24,
                        after: 80
                    },
                    //focusAt:'center',
                }
            }
        });
        blogSlider.mount({Swipe, Controls, Breakpoints});
    }
}
window.addEventListener("load", blogslider);


// Only mobile slider (home cocktail creations, footer blog)
function initOnlyMobileSlider() {
    const onlyMobileSlider = document.querySelectorAll('.only-mobile-slider');
    if (onlyMobileSlider) {
        onlyMobileSlider.forEach(slider => {
            const glideSlider = new Glide(slider, {
                type: 'slider',
                perView: 2,
                gap: 20,
                animationDuration: 600,
                animationTimingFunc: 'cubic-bezier( .42, 0, .58, 1 )',
                rewind: false,
                bound: true,
                peek: {
                    before: 24,
                    after: 120
                },
                breakpoints: {
                    639: {
                        perView: 1,
                        peek: {
                            before: 24, 
                            after: 120
                        }
                    },
                    420: {
                        perView: 1,
                        peek: {
                            before: 24,
                            after: 80
                        }
                    }
                }
            });
            glideSlider.mount({ Swipe, Controls, Breakpoints });
        });
    }
}
function refreshOnlyMobileSlider() {
    if (window.innerWidth <= 767) {
        initOnlyMobileSlider()
    }
}
window.addEventListener("load", refreshOnlyMobileSlider)
window.addEventListener('resize', refreshOnlyMobileSlider);


// Product slider
if (document.getElementById('half-slider')) {
    const halfSlider = new Glide('#half-slider', {
        type: 'slider',
        perView: 1,
        animationDuration: 600,
        animationTimingFunc: 'cubic-bezier( .42, 0, .58, 1 )',
        rewind: false,
        dragThreshold: 60,
        bound: true
    });
    // Show arrows if there is more then one slide
    halfSlider.on('mount.after', function () {
        const slides = document.querySelectorAll('.slideshow .box');
        const arrows = document.querySelectorAll('.half-slider-prev, .half-slider-next');
        if (slides.length > 1) {
            arrows.forEach(arrow => arrow.style.display = 'block');
        }
    });
    halfSlider.mount({Swipe, Controls, Breakpoints});
}

// Home portfolio slider
function initTimelineSlider() {
    if (document.getElementById('timeline-slider')) {
        // Calculate peek value on desktop
        const viewportWidth = window.innerWidth;
        const peekBeforeValue = (viewportWidth - 1460) / 2;
        // console.log('peek on load', peekBeforeValue);
        window.addEventListener('resize', () => {
            if (window.innerWidth >= 1605) {
                const viewportWidth = window.innerWidth;
                const peekBeforeValue = (viewportWidth - 1460) / 2;
                // console.log('peek on resize', peekBeforeValue);
            }
        });

        const timelineSlider = new Glide('#timeline-slider', {
            type: 'slider',
            perView: 3,
            animationDuration: 600,
            animationTimingFunc: 'cubic-bezier( .42, 0, .58, 1 )',
            rewind: false,
            gap: 20,
            dragDistance: 0,
            touchAngle: 0,
            bound: true,
            peek: { 
                before: peekBeforeValue, 
                after: 36 
            },
            breakpoints: {
                1604: {
                    peek: { 
                        before: 36, 
                        after: 36
                    },
                    perView: 3,
                },
                1023: {
                    peek: { 
                        before: 36, 
                        after: 36
                    },
                    perView: 3,
                    dragDistance: 60,
                    touchAngle: 45
                },
                767: {
                    perView: 2,
                    peek: {
                        before: 36, 
                        after: 36
                    },
                    dragDistance: 60,
                    touchAngle: 45
                },
                639: {
                    perView: 1,
                    peek: {
                        before: 36, 
                        after: 36
                    },
                    dragDistance: 60,
                    touchAngle: 45
                }
            }
        });

        // console.log(timelineSlider);

        timelineSlider.mount({Swipe, Controls, Breakpoints});
    }
}
window.addEventListener('load', initTimelineSlider);
window.addEventListener('resize', initTimelineSlider);


